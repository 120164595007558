

































import Vue from "vue";
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { AUTH } from "../../routes/endpoints";

export default Vue.extend({
    data() {
        return {
            loading: false,
            form: { email: "" },
            AUTH,
            haveSentResetInstructions: false,
        };
    },
    methods: {
        ...mapActions("auth", ["passwordForgot"]),
        async resetPassword() {
            try {
                this.loading = true;

                const payload = { username: this.form.email };

                const res = await this.passwordForgot(payload);

                if (res.status === 200) {
                    this.haveSentResetInstructions = true;
                }

                this.loading = false;
            } catch (error: unknown) {
                this.loading = false;
            }
        },
    },
    validations() {
        return {
            form: {
                email: { required, email },
            },
        };
    },
});
